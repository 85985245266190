import React from 'react';
import Head from 'next/head';
import DeliveryAPIError from 'components/contentstack/DeliveryAPIError';
import ContentstackRenderer from '../../../../components/contentstack/renderer';
import ErrorPage from 'components/contentstack/ErrorPage';

const ContentstackStaticContentTastic = ({ data }: any) => {
  const content = data?.data?.dataSource.payload?.[0] as any;

  if (!content) {
    return <ErrorPage />;
  } else if (content?.err) {
    return <DeliveryAPIError data={content.err} />;
  } else {
    return (
      <>
        <Head>
          <title>{content.data.title}</title>
        </Head>

        <ContentstackRenderer data={content.data} contentTypeUid={content.contentTypeUid} />
      </>
    );
  }
};

export default ContentstackStaticContentTastic;
